<template>
    <v-app class="desktopLayoutClass">
        <v-dialog
                    transition="dialog-bottom-transition"
                    width="80%"
                    v-model="uploadFieldDialog"
                >
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <v-toolbar
                                color="primary"
                                title="Opening from the bottom"
                                >Select Files
                            </v-toolbar>
                            <v-card-text>
                                <v-file-input label="File input"></v-file-input>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    variant="text"
                                    @click="isActive.value = false"
                                    >Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
        <!-- <v-row justify="space-around">
            <v-col cols="auto">
                
            </v-col>
        </v-row> -->
        <div class="headingContainer">
            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/back_button.svg" @click="takeMeBack" style="cursor: pointer;"/>
            <h3>Appointment Booking</h3>
        </div>
        <div v-if="loader || searchNurseFlag">
            <div v-if="loader">
                <div class="" >
                    <v-skeleton-loader elevation="0" type="list-item-avatar" />
                </div>
                <div class="d-flex flex-row pa-4" >
                    <v-skeleton-loader class="ma-4" style="height: 140px" elevation="0" type="card-avatar" />
                    <v-skeleton-loader class="ma-4" style="height: 140px" elevation="0" type="card-avatar" />
                    <v-skeleton-loader class="ma-4" style="height: 140px" elevation="0" type="card-avatar" />
                </div>
                <div class="pa-2" >
                    <v-skeleton-loader elevation="0" type="card" />
                </div>
                <div class="pa-4" style="text-align:center" >
                    <v-skeleton-loader elevation="0" type="button" />
                </div>
            </div>
            <div class="loader-wrapper">
                <p style="margin-left: 2rem;" v-if="searchNurseFlag">Searching for nurse availability</p>
            </div>
        </div>
        <div v-else-if="nurseNotAvailableFlag" class="noNurseLayout">
            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/nurse_NA_alert.svg" />
            <div class="noNurseTextStyle">
                <h3>No Nurses Found!</h3>
                <p>No Nurses available for the selected slots.
                    Sorry for the inconvenience caused.</p>
            </div>
            <div class="NoNurseButtonContainer">
                <v-btn color="#48ACEF" class="primary" width="100%" @click="backToBooking()">Back</v-btn>
                <v-btn color="#48ACEF" class="primary" outlined width="100%" @click="gotoHome()">Go To Home</v-btn>
            </div>
        </div>
        <div v-else>
            <div style="padding: 8px !important;">
                <nurseTypesComponent :nurseTypesProp="nurseTypes"></nurseTypesComponent>
            </div>
            <slotTypeComponent :shiftDetails="shiftTypes" :current-date="currentDate" :specialtyTypes="specialtyTypes">
            </slotTypeComponent>
            <patientDetailsComponent :dependent-list="dependentList" :address-list="addressList"
                :customerMedicalInfo="customerMedicalInfo">
            </patientDetailsComponent>
            <div style="width: 100%; padding: 10px;">
                <v-btn class="primary" style="text-transform: capitalize; width:100%" color="#48ACEF"
                    @click="searchNurse()" :disabled="bookAppointmentButtonFlag">
                    <!-- <img style="margin-right: .2rem" src="https://d3vsnl8idgwrlw.cloudfront.net/upcomingSlot.svg"> -->
                    <span class="bookAppointment_button_content" >Search Nurse</span>
                </v-btn>
            </div>
        </div>
    </v-app>
</template>
<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import nurseTypesComponent from '../../components/nurseBookingComponents/nurseTypes.vue';
import slotTypeComponent from '../../components/nurseBookingComponents/slotType.vue';
import patientDetailsComponent from '../../components/nurseBookingComponents/patientDetailsComponent.vue';
import { mixinFunctions } from '../../mixins/index';
export default {
    name: 'nurseBookingPage',
    mixins:[mixinFunctions],
    components: {
        nurseTypesComponent,
        slotTypeComponent,
        patientDetailsComponent
    },
    data() {
        return {
            bookingPageResponse: {},
            nurseTypes: [],
            shiftTypes: [],
            selectedDescription: '',
            loader: false,
            nurseTypesComponentRefresher: 0,
            slotTypeComponentRefresher: 0,
            patientDetailsComponentRefresher: 0,
            currentDate: null,
            addressList: [],
            dependentList: [],
            selectAddressFlag: false,
            customerMedicalInfo: {},
            searchNurseFlag: false,
            nurseNotAvailableFlag: false,
            bookAppointmentButtonFlag: false,
            addressHasValue: false,
            patientHasValue: false,
            dateHasValue: false,
            nurseSpecialtyHasValues: false,
            token: null,
            uploadFieldDialog: false,
            specialtyTypes: []
        }
    },
    methods: {
        openUploadFileDialog () {
            this.uploadFieldDialog = true;
        },
        takeMeBack() {
            this.$router.go(-1);
        },
        async fetchAllBookingPageDetails() {
            try {
                this.bookingPageResponse = await axios_auth_instance_nurseBooking.get("/pagewise/bookingPage")
                this.nurseTypes = this.bookingPageResponse.data.data.nurseTypes
                this.shiftTypes = this.bookingPageResponse.data.data.shiftTypes
                this.currentDate = this.bookingPageResponse.data.data.currentTimestamp
                this.addressList = this.bookingPageResponse.data.data.addressDetails
                this.dependentList = this.bookingPageResponse.data.data.dependentDetails
                this.customerMedicalInfo = this.bookingPageResponse.data.data.customerMedicalDetails
                this.specialtyTypes = this.bookingPageResponse.data.data.skillSets
                this.nurseTypesComponentRefresher++
                this.loader = false
            } catch (error) {
                console.log("fetchAllBookingPageDetails Error:",error);
                if (error.response.status === 401) {
                    this.$cookies.remove('customerToken');
                    this.$router.push({
                        name: "Login",
                    });
                }
            }
        },
        searchNurse(){
            this.$router.push({ path: '/summaryPage' });
        },
        gotoHome() {
            if (!window.xm) {
				this.$router.push({
					name: 'CustomerHomeScreen'
				});
			} else {
                this.$router.push({ path: '/' });
            }
        },
        backToBooking() {
            this.nurseNotAvailableFlag = false
        },
        mandatoryFieldCheck() {
            if(this.addressHasValue && this.patientHasValue && this.dateHasValue && this.nurseSpecialtyHasValues){
                this.bookAppointmentButtonFlag = false
            }else{
                this.bookAppointmentButtonFlag = true
            }
        }

    },
    computed: {
        ...mapState({
            addressDetails: state => state.nurseBooking.addressDetails,
            patientDetails: state => state.nurseBooking.patientDetails,
            dates: state => state.nurseBooking.selectedDates,
            nurseSpecialtyDetails: state => state.nurseBooking.nurseSpecialtyDetails
        }),
    },
    watch: {
        addressDetails(newValue) {
            if(newValue.address_unique_id !== undefined){
                this.addressHasValue = true
                this.mandatoryFieldCheck()
            }else{
                this.addressHasValue = false
            }
        },
        patientDetails(newValue) {
            if(newValue.name !== undefined){
                this.patientHasValue = true
                this.mandatoryFieldCheck()
            }else{
                this.patientHasValue = false
            }
        },
        dates(newValue) {
            if(newValue.length > 0){
                this.dateHasValue = true
                this.mandatoryFieldCheck()
            }else{
                this.dateHasValue = false
            }
        },
        nurseSpecialtyDetails(newValue){
            if(newValue.length !== ''){
                this.nurseSpecialtyHasValues = true
                this.mandatoryFieldCheck()
            }else{
                this.nurseSpecialtyHasValues = false
            }
        }
    },

    async mounted() {
        this.loader = true;
        await this.fetchAllBookingPageDetails();
        this.selectAddressFlag = true;
        let addressDetails = this.$store.state.nurseBooking.addressDetails;
        let patientDetails = this.$store.state.nurseBooking.patientDetails;
        let dates = this.$store.state.nurseBooking.selectedDates;
        let nurseSpecialty = this.$store.state.nurseBooking.nurseSpecialtyDetails;
        if(JSON.stringify(addressDetails) !== JSON.stringify({} || '')) this.addressHasValue = true;
        if(JSON.stringify(patientDetails) !== JSON.stringify({} || '')) this.patientHasValue = true;
        if(JSON.stringify(patientDetails) !== JSON.stringify([] || '') && dates.length > 0) this.dateHasValue = true;
        if(nurseSpecialty !== '') this.nurseSpecialtyHasValues = true;
        this.mandatoryFieldCheck();
        this.userPageViewTable('NBABookingPage');
    }
}

</script>
<style scoped>
.loader-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 8px solid #f3f3f3!important;
    border-top: 8px solid #3498db !important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.headingContainer {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 2px solid #E0E0E0 !important;
    margin-bottom: 2px;
}

.noNurseTextStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.noNurseTextStyle p {
    line-height: 24px;
}

.noNurseLayout {
    display: inline-flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 20% 5% 0px 5%;
}

.NoNurseButtonContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bookAppointment_button_content{
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
}
.boxStyle {
    display: flex;
    padding: 16px;
    flex-direction: column;
    /* width: 100% !important; */
    align-items: flex-start;
    /* align-self: stretch; */
    border-radius: 4px;
    border: 1px solid var(--Grays-Gray-5, #E0E0E0);
    background: #FFF;
    font-weight: 600 !important;
    margin: 10px;
}
.uploadDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Grays-Gray-2, #4F4F4F);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;   
    line-height: 21px; /* 150% */
    margin-bottom: 10px;
}
.uploadDescription img {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
